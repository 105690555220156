import React, { useState } from 'react'
import YearPicker from './_components/YearPicker/YearPicker';
import useGetFromServer from '../../utils/useGetFromServer';
import MainSection from './_components/MainSection/main';
import ResetSidebar from '../../components/Common/ResetSidebar';
import Footer from './_components/Footer/footer';


export function QuotesPreparation() {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(500)

  const params = new URLSearchParams({
    selectedYear,
    page,
    limit
  });

  const { result, loading } = useGetFromServer(`/prep-quotes?${params}`, [selectedYear, page, limit])

  function handlePageChange(page) {
    setPage(page)
  }

  function getYear(year) {
    setSelectedYear(year)
    setPage(1)
    setLimit(500)
  }

  function handleLimitChange(limit) {
    setLimit(limit)
    setPage(1)
  }

  return (
    <>
      <ResetSidebar />
      <YearPicker getYear={getYear} />
      <MainSection selectedYear={selectedYear} loading={loading} data={result.data} />
      <Footer
        totalPages={result.totalPages}
        currentPage={result.currentPage}
        handlePageChange={handlePageChange}
        handleLimitChange={handleLimitChange}
      />
    </>
  )
}
