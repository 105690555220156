import { configureStore } from '@reduxjs/toolkit';
import layoutSlice from './layoutSlice';
import userSlice from './userSlice';
import tagsSlice from './tagsSlice';
import filterSlice from './filterSlice';
import bulkTagSlice from './bulkTagSlice';
import TabsSlice from './AdvanceQuotes/TabsSlice';
import QuotesTable from './AdvanceQuotes/QuotesTable';
import notesFilterSlice from './notesFilterSlice';
import notesSlice from './notesSlice';
import quoteFilterSlice from './quoteFilterSlice';
import jobFilterSlice from './jobFilterSlice';
import automationFilterSlice from './automationFilterSlice';
import visitFilterSlice from './visitFilterSlice';
import automationTable from './automationTable';

const store = configureStore({
  reducer: {
    layout: layoutSlice,
    user: userSlice,
    tag: tagsSlice,
    filters: filterSlice,
    bulkTag: bulkTagSlice,
    tabs: TabsSlice,
    quotesTable: QuotesTable,
    automationTable: automationTable,
    notesFilter: notesFilterSlice,
    automationFilter: automationFilterSlice,
    visitFilter: visitFilterSlice,
    jobFilter: jobFilterSlice,
    quoteFilter: quoteFilterSlice,
    noteSetting: notesSlice,
  }
});

export default store;
