import React, { useState } from 'react';
import { formatNumber, formatNumberWithSpaces, formatYearDateTimeDay } from '../../../components/Common/Validations';
import TableDescription from './TableDescription';
import { Link } from 'react-router-dom';
import { MdDeleteForever, MdReplay } from 'react-icons/md';
import { FiEdit } from 'react-icons/fi';
import { IoClose } from 'react-icons/io5';
import { FaRecycle } from 'react-icons/fa';
import { IoMdSave } from 'react-icons/io';
import { Divider } from '@jobber/components/Divider';
import { Spinner } from '@jobber/components/Spinner';
import { useDispatch } from 'react-redux';
import { setPreservedR } from '../../../store/AdvanceQuotes/QuotesTable';
import { deleteFromServer, getFromServer, postToServer } from '../../../components/Common/requests';
import { Tooltip } from '@jobber/components/Tooltip';
import { showToast } from '@jobber/components/Toast';
import ConfirmModal from '../../Index/TagCloudCategory/ConfirmModal';
import { Heading } from '@jobber/components/Heading';
import QuoteNote from './_components/QuoteNote';
import { SiQuickbooks } from "react-icons/si";

function TableRow({
  s,
  k,
  curentTabId,
  isAdvanceQuotes,
  matchingHighlight,
  handleDoubleClick,
  visibleColumns,
  editId,
  columnData,
  setColumnData,
  columnLoader,
  handleSaveColumnRowData,
  setEditId,
  handleEditButton,
  handleDeleteButton,
  isRecycle,
  reloadData
}) {
  const [rowData, setRowData] = useState(s)
  const dispatch = useDispatch();
  const preserveStateHandler = (event) => {
    if (!event.ctrlKey) {
      dispatch(setPreservedR(true));
    }
  };
  const [deleteLoading, setDeleteLoading] = useState(false);
  const handleDelete = async (type, row, index) => {
    setDeleteLoading(true);
    let result = {};
    if (type == 'restore') {
      result = await deleteFromServer(`mark-quotes-as-notdeleted/${row?.client}`);
    } else {
      if (isRecycle) {
        result = await deleteFromServer(`quote/${row?.client}`);
      } else {
        result = await deleteFromServer(`mark-quotes-as-deleted/${row?.client}`);
      }
    }
    if (result.status) {
      await handleDeleteButton(row);
      if (type == 'restore') {
        showToast({
          message: 'Quote restored successfully'
        });
      } else {
        if (isRecycle) {
          showToast({
            message: 'Quote deleted successfully'
          });
        } else {
          showToast({
            message: 'Quote moved to recycle page successfully'
          });
        }
      }
    } else {
      showToast({
        message: result.message || result.error,
        variation: 'error'
      });
    }
    setDeleteLoading(false);
  };
  const [auditModal, setAuditModal] = useState(false);
  const [auditLoading, setAuditLoading] = useState(false);
  const auditHandler = async () => {
    setAuditModal(false);
    setAuditLoading(true);
    const result = await getFromServer(`audit-quote/${curentTabId}?clientId=${rowData?.client}`);
    if (result.status) {
      showToast({
        message: 'Row audit successfully'
      });
      setRowData(result?.data?.quotes?.[0])
    } else {
      showToast({
        message: result.message || result.error,
        variation: 'error'
      });
    }
    setAuditLoading(false);
  };

  const [totalQBCost, setTotalQBCost] = useState(s?.totalQBCost)
  const [amountQBPaid, setAmountQBPaid] = useState(s?.amountQBPaid)
  const [amountQBOwed, setAmountQBOwed] = useState(s?.amountQBOwed)
  const [isQBLoading, setIsQBLoading] = useState(false);

  async function syncClient(clientName) {
    setIsQBLoading(true);
    showToast({
      message: `Syncing ${clientName} with Quickbooks`,
      variation: 'info'
    });
    const response = await postToServer(`qb/sync-client/${clientName}`)
    if (response.status) {
      const { totalQBCost, amountQBPaid, amountQBOwed } = response?.data?.[0];
      setTotalQBCost(totalQBCost);
      setAmountQBPaid(amountQBPaid);
      setAmountQBOwed(amountQBOwed);
      showToast({
        message: 'Client synced with Quickbooks',
        variation: 'success'
      });
    } else {
      showToast({
        message: response.message || response.error,
        variation: 'error'
      });
    }
    setIsQBLoading(false);
  }

  return (
    <>
      <tr
        key={rowData._id}
        className="pointer"
        style={{
          // position: 'relative',
          // zIndex: '1',
          '--bs-table-bg': !isRecycle && matchingHighlight?.isHighlight ? matchingHighlight.color || '' : ''
        }}
        onDoubleClick={(e) => handleDoubleClick(e, rowData.client, matchingHighlight?.isHighlight)}
      >
        <TableDescription isUrl={false} isVisible={visibleColumns?.notes}>
          <QuoteNote initialData={rowData?.notes} clientId={rowData?.client} />
        </TableDescription>
        <TableDescription url={rowData?.jobberWebUri} isVisible={visibleColumns?.name}>
          {/* {rowData?.clientFirstName} {rowData?.clientLastName} */}
          <Heading level={5}>{rowData?.clientName}</Heading>
          <p style={{ color: 'var(--muted)' }}>{rowData?.clientSecondaryName}</p>
        </TableDescription>
        <TableDescription url={rowData?.jobberWebUri} isVisible={visibleColumns?.title}>
          {rowData?.quoteTitle}
        </TableDescription>
        <TableDescription url={rowData?.jobberWebUri} isVisible={visibleColumns?.address}>
          {rowData?.uniquePropertyAddresses?.map((c, index, array) => {
            return (
              <>
                {c?.street}
                {index !== array.length - 1 && (
                  <div className="my-2">
                    <Divider direction={'horizontal'} />
                  </div>
                )}
              </>
            );
          })}
        </TableDescription>
        <TableDescription url={rowData?.jobberWebUri} isVisible={visibleColumns?.zipCode}>
          {rowData?.uniquePropertyAddresses?.map((c, index, array) => {
            return (
              <>
                {c.postalCode || '-'}
                {index !== array.length - 1 && (
                  <div className="my-2">
                    <Divider direction={'horizontal'} />
                  </div>
                )}
              </>
            );
          })}
        </TableDescription>
        <TableDescription url={rowData?.jobberWebUri} isVisible={visibleColumns?.phone}>
          {rowData?.clientPhone}
        </TableDescription>
        <TableDescription url={rowData?.jobberWebUri} isVisible={visibleColumns?.email}>
          {rowData.clientEmail}
        </TableDescription>
        <TableDescription url={rowData?.jobberWebUri} isVisible={visibleColumns?.approved}>
          {rowData?.approved}
        </TableDescription>
        <TableDescription url={rowData?.jobberWebUri} isUrl={false} isVisible={visibleColumns?.details}>
          {!isRecycle ? (
            <Link
              to={{ pathname: `/advance-quotes/all/${rowData.client}` }}
              className="text-primary animate text-decoration-underline"
              onClick={preserveStateHandler}
            >
              Details
            </Link>
          ) : (
            'Restore to view Detail page'
          )}
        </TableDescription>
        <TableDescription url={rowData?.jobberWebUri} isUrl={editId != k} isVisible={visibleColumns?.totalCost}>
          {editId == k ? (
            s?.uniquePropertyAddresses?.map((c, index, array) => {
              return (
                <>
                  <input
                    placeholder="Total Cost"
                    min={0}
                    step={0.1}
                    type="number"
                    onDoubleClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    style={{ width: '5rem' }}
                    value={columnData?.[index]?.totalCost}
                    // value={0}
                    onChange={(e) => {
                      let val = e.target.value;
                      if (!val || !Number(val) || val < 0) val = 0;
                      const newData = [...columnData];
                      newData[index] = {
                        ...newData[index],
                        totalCost: val == 0 ? val : val?.replace(/^0+(?=\d)/, '')
                      };
                      setColumnData(newData);
                    }}
                  />
                  {index !== array.length - 1 && (
                    <div className="my-2">
                      <Divider direction={'horizontal'} />
                    </div>
                  )}
                </>
              );
            })
          ) : (
            <div>
              {rowData?.uniquePropertyAddresses?.map((c, index, array) => {
                return (
                  <>
                    <span style={{ textWrap: 'nowrap' }}>${formatNumberWithSpaces(formatNumber(c?.groupTotalCost))}</span>
                    {index !== array.length - 1 && (
                      <div className="my-2">
                        <Divider direction={'horizontal'} />
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          )}
        </TableDescription>
        <TableDescription url={rowData?.jobberWebUri} isUrl={editId != k} isVisible={visibleColumns?.amountPaid}>
          {editId == k ? (
            s?.uniquePropertyAddresses?.map((c, index, array) => {
              return (
                <>
                  <input
                    placeholder="Paid"
                    min={0}
                    step={0.1}
                    type="number"
                    onDoubleClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    style={{ width: '5rem' }}
                    value={columnData?.[index]?.amountPaid}
                    onChange={(e) => {
                      let val = e.target.value;
                      if (!val || !Number(val) || val < 0) val = 0;
                      const newData = [...columnData];
                      newData[index] = {
                        ...newData[index],
                        amountPaid: val == 0 ? val : val?.replace(/^0+(?=\d)/, '')
                      };
                      setColumnData(newData);
                    }}
                  />
                  {index !== array.length - 1 && (
                    <div className="my-2">
                      <Divider direction={'horizontal'} />
                    </div>
                  )}
                </>
              );
            })
          ) : (
            <div>
              {rowData?.uniquePropertyAddresses?.map((c, index, array) => {
                return (
                  <>
                    <span style={{ textWrap: 'nowrap' }}>${formatNumberWithSpaces(formatNumber(c?.groupAmountPaid))}</span>
                    {index !== array.length - 1 && (
                      <div className="my-2">
                        <Divider direction={'horizontal'} />
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          )}
        </TableDescription>
        <TableDescription url={rowData?.jobberWebUri} isUrl={editId != k} isVisible={visibleColumns?.amountOwed}>
          {editId == k ? (
            s?.uniquePropertyAddresses?.map((c, index, array) => {
              return (
                <>
                  <input
                    placeholder="Owed"
                    min={0}
                    step={0.1}
                    type="number"
                    style={{ width: '5rem' }}
                    onDoubleClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    value={columnData?.[index]?.amountOwed}
                    onChange={(e) => {
                      let val = e.target.value;
                      if (!val || !Number(val) || val < 0) val = 0;

                      const newData = [...columnData];
                      newData[index] = {
                        ...newData[index],
                        amountOwed: val == 0 ? val : val?.replace(/^0+(?=\d)/, '')
                      };
                      setColumnData(newData);
                    }}
                  />
                  {index !== array.length - 1 && (
                    <div className="my-2">
                      <Divider direction={'horizontal'} />
                    </div>
                  )}
                </>
              );
            })
          ) : (
            <div>
              {rowData?.uniquePropertyAddresses?.map((c, index, array) => {
                return (
                  <>
                    <span style={{ textWrap: 'nowrap' }}>${formatNumberWithSpaces(formatNumber(c?.groupAmountOwed))}</span>
                    {index !== array.length - 1 && (
                      <div className="my-2">
                        <Divider direction={'horizontal'} />
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          )}
        </TableDescription>
        <TableDescription url={rowData?.jobberWebUri} isVisible={visibleColumns?.QBtotalCost}>
          <span style={{ textWrap: 'nowrap' }}>${formatNumberWithSpaces(formatNumber(totalQBCost)) || 0}</span>
        </TableDescription>
        <TableDescription url={rowData?.jobberWebUri} isVisible={visibleColumns?.QBamountPaid}>
          <span style={{ textWrap: 'nowrap' }}>${formatNumberWithSpaces(formatNumber(amountQBPaid)) || 0}</span>
        </TableDescription>
        <TableDescription url={rowData?.jobberWebUri} isVisible={visibleColumns?.QBamountOwed}>
          <span style={{ textWrap: 'nowrap' }}>${formatNumberWithSpaces(formatNumber(amountQBOwed)) || 0}</span>
        </TableDescription>
        <TableDescription url={rowData?.jobberWebUri} isVisible={visibleColumns?.date}>
          {formatYearDateTimeDay(s?.quote_createdAt)}
        </TableDescription>
        <TableDescription url={rowData?.jobberWebUri} isVisible={visibleColumns?.approvedDate}>
          {rowData?.approved_at ? formatYearDateTimeDay(s?.approved_at) : '-'}
        </TableDescription>
        <TableDescription url={rowData?.jobberWebUri} isVisible={visibleColumns?.totalAmountOwed}>
          <span style={{ textWrap: 'nowrap' }}>${formatNumberWithSpaces(formatNumber(s?.totalAmountOwed)) || 0}</span>
        </TableDescription>
        {isAdvanceQuotes && (
          <td>
            {editId == k ? (
              <>
                <div className="d-flex gap-2">
                  {columnLoader ? (
                    <Spinner size="small" />
                  ) : (
                    <IoMdSave className="fs-5 pointer" title="save" onClick={handleSaveColumnRowData} />
                  )}
                  <IoClose className="fs-5 pointer" title="close" onClick={() => setEditId(-1)} />
                </div>
              </>
            ) : (
              <div className="d-flex gap-1">
                {deleteLoading ? (
                  <Spinner size="small" />
                ) : (
                  <>
                    {isRecycle ? (
                      <Tooltip message={'Restore'}>
                        <FaRecycle className="pointer fs-6 muted" onClick={() => handleDelete('restore', s, k)} />
                      </Tooltip>
                    ) : (
                      <Tooltip message={'Edit'}>
                        <FiEdit className="pointer fs-6 muted" onClick={() => handleEditButton(s, k)} />
                      </Tooltip>
                    )}
                    {!isRecycle && <Tooltip message={'Audit Quote'}>
                      {auditLoading ? (
                        <Spinner size="small" />
                      ) : (
                        <MdReplay className="pointer text-success fs-5 muted" onClick={() => setAuditModal(true)} />
                      )}
                    </Tooltip>}
                    <Tooltip message={'Delete'}>
                      <MdDeleteForever className="pointer text-danger fs-5 muted" onClick={() => handleDelete('delete', s, k)} />
                    </Tooltip>
                    {isQBLoading ? (
                      <Spinner size="small" />
                    ) : (
                      <Tooltip message={'Sync QB'}>
                        <SiQuickbooks className="pointer fs-5 muted" onClick={() => syncClient(s?.clientName)} />
                      </Tooltip>
                    )}
                  </>
                )}
              </div>
            )}
          </td>
        )}
      </tr>
      {auditModal && (
        <ConfirmModal
          onConfirm={auditHandler}
          buttonName={'Audit'}
          title={'Warning'}
          message="Are you sure? We recommend taking backup"
          open={auditModal}
          onCancel={() => setAuditModal(false)}
        />
      )}
    </>
  );
}

export default TableRow;
