import React, { useRef } from 'react'
import { Disclosure } from "@jobber/components/Disclosure";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { Divider } from "@jobber/components/Divider";
import { Spinner } from "@jobber/components/Spinner";
import { useVirtualizer } from '@tanstack/react-virtual';
import { getFromServer } from '../../../../components/Common/requests';

export default function MainSection({ selectedYear, data, loading }) {
  if (loading) {
    return renderContent(<Spinner size={"base"} />);
  }

  if (!data.length) {
    return renderContent(<p>No data to display</p>);
  }

  function modifyArray(arr) {
    if (arr.length === 1) {
      return arr[0].replace(/,/g, '')
    }
    return arr.slice(0, -1).map(str => str.split(" ")[0].replace(/,/g, '')).join(", ") + ', ' + arr[arr.length - 1].replace(/,/g, '')
  }

  async function handleClick(title, lineItemsNames) {
    const params = new URLSearchParams({
      title,
      lineItemsNames,
      selectedYear
    });

    const { data } = await getFromServer(`prep-quoteTitles?${params}`)

    console.log(data)

  }

  return (
    <div className='py-4 d-flex flex-column gap-3'>
      {data?.map(quote => (
        <Disclosure key={`${quote.title} ${quote.totalQuoteCount}`} title={`${quote.title} (${quote.totalQuoteCount})`}>
          <Content>
            {quote.lineItemGroups.map(group => (
              <div className='px-4'>
                <Disclosure key={group.lineItemsNames.join()} title={`${modifyArray(group.lineItemsNames)} (${group.quoteCount})`}>
                  <VirtualizedContent quotes={group.quotes} />
                </Disclosure>
                <Divider direction={"horizontal"} />
              </div>
            ))}
          </Content>
        </Disclosure>
      ))}
    </div>
  )
}

function renderContent(content) {
  const style = {
    height: '80%',
    width: '100%',
    display: 'grid',
    placeItems: 'center'
  };

  return (
    <div style={style}>
      {content}
    </div>
  )
}

function VirtualizedContent({ quotes }) {
  const parentRef = useRef();

  const rowVirtualizer = useVirtualizer({
    count: quotes.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 35, // Adjust according to the estimated height of each quote item
  });

  return (
    <div style={{ flex: '1' }}>
      <Content>
        <div ref={parentRef} style={{ maxHeight: '400px', overflowY: 'auto', position: 'relative' }}>
          <div style={{
            display: 'grid',
            gridTemplateColumns: '2fr 1fr 1fr',
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            zIndex: 1,
            padding: '0.2rem 0',
          }}>
            <p className='m-0'><b>Original Quotes</b></p>
            <p className='m-0'><b>Not processed</b></p>
            <p className='m-0'><b>Quotes Processed</b></p>
          </div>
          <div
            style={{
              height: `${rowVirtualizer.getTotalSize()}px`,
              position: 'relative',
            }}
          >
            {rowVirtualizer.getVirtualItems().map(virtualRow => (
              <div
                key={quotes[virtualRow.index].quoteNumber}
                ref={virtualRow.measureElement}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  transform: `translateY(${virtualRow.start}px)`,
                  display: 'grid',
                  gridTemplateColumns: '2fr 1fr 1fr',
                }}
              >
                <Text>{`${quotes[virtualRow.index].clientName} Q#${quotes[virtualRow.index].quoteNumber}`}</Text>
                <Text>true</Text>
                <Text>false</Text>
              </div>
            ))}
          </div>
        </div>
      </Content>
    </div>
  );
}
