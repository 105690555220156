import React, { useRef } from 'react'
import Datetime from "react-datetime";
import moment from 'moment';
import 'react-datetime/css/react-datetime.css';
import './yearPicker.css'

function YearPicker({ getYear }) {
  const yearRef = useRef(new Date().getFullYear())

  function changeYearHandler(date) {
    yearRef.current = date.year()
    getYear(date.year())
  }

  const style = {
    boxSizing: 'border-box',
    border: 'none',
    caretColor: 'transparent',
    color: 'white',
    outline: 'none',
    textAlign: 'center',
    width: '60px',
    padding: '10px',
    backgroundColor: 'var(--green)',
    borderRadius: '5px',
    cursor: 'pointer',
  }

  const outputStyle = {
    ...style,
    cursor: 'default',
    backgroundColor: 'transparent',
    border: '2px solid var(--green)',
    color: 'var(--green)'
  }

  const startOfCurrentYear = moment().add(1, 'year').startOf('year');
  function valid(current) {
    return current.isBefore(startOfCurrentYear);
  };

  return (
    <div className='d-flex gap-3'>
      <div className='d-flex flex-column gap-2'>
        <h2 className='w-100 m-0 fs-6'>Source Year: </h2>
        <Datetime
          isValidDate={valid}
          initialValue={new Date()}
          closeOnSelect
          inputProps={{ className: '', style }}
          timeFormat={false}
          dateFormat="YYYY"
          onChange={changeYearHandler}
        />
      </div>

      <div className='d-flex flex-column gap-2'>
        <h2 className='w-100 m-0 fs-6'>Resulting Year: </h2>
        <p style={outputStyle} className='m-0'>
          {yearRef.current + 1}
        </p>
      </div>
    </div>
  )
}

export default YearPicker
