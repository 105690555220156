import { FaCirclePlus } from 'react-icons/fa6';
import { putToServer } from '../../../../components/Common/requests';
import { useRef, useState } from 'react';
import { showToast } from "@jobber/components/Toast";
import { Spinner } from '@jobber/components/Spinner';

export default function QuoteNote({ initialData, clientId }) {
  const inputRef = useRef(null);
  const [note, setNote] = useState(initialData || "");
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  if (isLoading) {
    return <Spinner size='small' />
  }

  function enableInput() {
    setNote((prev) => prev);
    setIsEditing(true);
    setTimeout(() => {
      inputRef.current?.focus();
      inputRef.current?.setSelectionRange(0, inputRef.current.value.length);
    }, 0);
  };

  function disableInput() {
    setIsEditing(false);
    showToast({ variation: 'info', message: 'Please press enter while editing to update quote' })
  };

  async function updateQuoteNote() {
    setIsLoading(true);

    try {
      const result = await putToServer('update-client-note', {
        id: clientId,
        notes: note,
      });

      if (!result.status) {
        showToast({ variation: 'error', message: result.message });
        return
      }

      setNote(result?.data);
      showToast({ variation: 'success', message: result.message });
    } catch (error) {
      showToast({ variation: 'error', message: error.message || 'An unexpected error occurred' });
    } finally {
      setIsLoading(false);
    }
  };

  function onChange(event) {
    setNote(event.target.value);
  };

  function onKeyDown(event) {
    if (event.key === "Enter") {
      setIsEditing(false);
      updateQuoteNote()
    }
  };

  function buttonChildren() {
    if (!!note) return <p className='text-truncate text-start' style={{ color: 'var(--muted)', margin: 0, width: '100px' }}>{note}</p>
    return <FaCirclePlus
      className="fs-4 text-green animate"
    />
  }

  return (
    <>
      {isEditing ? (
        <textarea
          cols={8}
          ref={inputRef}
          onClick={enableInput}
          onBlur={disableInput}
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={note}
          onDoubleClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        />
      ) : (
        <button className='border-0 bg-transparent' onClick={enableInput}>
          {buttonChildren()}
        </button>
      )}
    </>
  );
}
